.app {
  text-align: center;
  padding: 0.6em 0.6em 0;
}

h2 {
  color: var(--aqua);
  font-size: 1.3em;
}

button {
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 6px;
  padding: 0.5em 1.3em;
  cursor: pointer;
}

.loading-image {
  animation: rotate 1s linear infinite;
}

/* Below CSS used to create the loading dots */
.loader {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 32px 0;
}

.loader-dot {
  border-radius: 50%;
  border: 5px solid var(--light-blue);
  margin: 10px;
}

.loader-dot:nth-child(1) {
  animation: twincle .6s ease-in-out alternate infinite;
}

.loader-dot:nth-child(2) {
  animation: twincle .6s ease-in-out alternate .2s infinite;
}

.loader-dot:nth-child(3) {
  animation: twincle .6s ease-in-out alternate .4s infinite;
}