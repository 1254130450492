@import './styles/variables.css';
@import 'styles/animations.css';
@import './components/App.css';
@import './components/Navbar.css';
@import './components/LoadingIconPicker.css';
@import './components/StartDatePicker.css';
@import './components/Post.css';
@import './components/ScrollTopBtn.css';
@import './components/pages/Developer.css';
@import './components/Project.css';
@import './components/FloatingAstronaut.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--light);
  background-color: var(--dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
