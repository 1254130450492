.post {
  background-color: var(--darker);
  width: 90vw;
  margin: 0 auto 1em auto;
  border-radius: 8px;
  box-shadow: 8px 8px 3px var(--shadow);
}

.post-img {
  width: 90vw;
  border-radius: 8px 8px 0 0;
}

.post-vid {
  border: 0;
  height: 50vw;
}

.post-vid:hover {
  outline: 2px solid var(--red);
}

.post-title {
  color: var(--yellow);
  margin: 1em 0.35em;
}

.post-desc {
  padding: 0 1em;
  text-align: left;
}

@media screen and (min-width: 992px) {
  .post {
    width: 70vw;
    margin: 0 auto 3em auto;
    border-radius: 12px;
  }
  
  .post-img {
    width: 70vw;
    border-radius: 12px 12px 0 0;
  }

  .post-vid {
    height: 40vw;
  }

  .post-desc {
    padding: 0 2em;
  }
}

.post-like-btn {
  color: var(--light-blue);
  background-color: var(--darker);
  border: 2px solid var(--light-blue);
  margin-bottom: 1.5em;
}

.post-like-btn:hover, .post-like-btn:focus {
  color: var(--darker);
  background-color: var(--light-blue);
}