@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes twincle {
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    border-radius: 35%;
  }
}