:root {
  --dark: #343a40;
  --darker: #272121;
  --light: #f8f9fa;
  --purple: #A5A4EA;
  --light-blue: #A7C6FE;
  --yellow: #ffc107;
  --aqua: #5bc0de;
  --green: #80E096;
  --pink: #E68989;
  --red: #FF0000;
  --shadow: rgba(34, 25, 25, 0.4);
}