.scroll-top-btn {
  color: var(--pink);
  background-color: var(--dark);
  border: 2px solid var(--pink);
  position: fixed;
  bottom: 55px;
  right: 10px;
  font-size: 2em;
  border-radius: 50%;
  padding: 0.4em 0.6em;
}

.scroll-top-btn:hover, .scroll-top-btn:focus {
  color: var(--darker);
  background-color: var(--pink);
}

@media screen and (min-width: 992px) {
  .scroll-top-btn {
    background-color: var(--darker);
    right: 30px;
  }
}